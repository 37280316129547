import React from "react";



function VideoProdPortfolio() {


    return (

        <div className="blockchain">
            <img src="./key.svg" alt=""></img>
            <div className="container">
                <h2>
                    Here's some reviews:
                </h2>
            </div>
            <div className="container">
                <a href="/portfolio/webcontent"> And we do content for it</a>
            </div>
            <div className="container">
                <a href="/portfolio/webdevelopment"> And we do web dev for it</a>
            </div>
            <div className="container">
                <a href="../contact">To make an order contact us RIGHT HERE!</a>
            </div>
        </div>

    );
}


export default VideoProdPortfolio;
