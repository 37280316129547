import './App.css';


function App() {
  return (
    <div className="homeButton">
      <a className="btn btn-outline-dark mt-5" href="/" role="button">Home</a>
    </div>
    
  );
}
export default App;
